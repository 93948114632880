<template>
    <div class="auth-section">
        <div class="image-section">
            <div class="image-content">
                <!--<img class="mx-auto img-fluid left-logo mt-5" width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo">-->
                <h1 class="mt-3">Welcome to LongLeap.One</h1>
                <div class="mt-3">
                    <img class="img-fluid" src="../../assets/images/login.png" alt="">
                </div>
            </div>
        </div>
        <div v-if="!showVerify"
            class="form-section">
            <div class="form-content">
                <img id="longleap_logo"
                    class="mx-auto img-fluid"
                    width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo" />

                <h1 class="m-0">Sign In</h1>

                <el-form ref="form"
                    :model="formData"
                    :rules="rules" class="mt-3"
                    label-position="top"
                    >

                    <el-form-item label="Email"
                        prop="email">
                        <el-input
                            v-model="formData.email"
                            @keyup.enter.native="handleSubmit('form')"
                            type="email" />
                    </el-form-item>
                    <!-- <router-link
                        class="d-flex justify-content-end mt-n3"
                        to="/forgot-email">Forgot
                        Email?
                    </router-link> -->
                    <el-form-item label="Password"
                        prop="password">
                        <el-input
                            v-model="formData.password"
                            @keyup.enter.native="handleSubmit('form')"
                            :type="showPassword ? 'text' : 'password'" >
                            <el-button
                                slot="append"
                                @click.prevent="showPassword = !showPassword">
                                <svg v-if="showPassword"
                                    style="width: 15px; height: 15px"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                <svg v-if="!showPassword"
                                    style="width: 15px; height: 15px"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <router-link
                        class="d-flex justify-content-end mt-n3"
                        to="/forgot-password">Forgot
                        Password?
                    </router-link>

                    <el-checkbox class="d-none"
                        v-model="formData.remember_me">Remember
                        me</el-checkbox>

                    <el-button class="mt-3"
                        style="width: 100%"
                        type="success"
                        :loading="loading"
                        @click="handleSubmit('form')">Sign
                        In
                    </el-button>

                </el-form>

                <div class="text-center mt-3">
                    Don't have an account?
                    <router-link
                        to="/signup">Create a New
                        Account for
                        Free</router-link>
                </div>
            </div>
        </div>

        <div v-if="showVerify"
            class="form-section">
            <div class="form-content">
                <img id="longleap_logo"
                    class="mx-auto img-fluid"
                    width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo" />

                <h1 class="pageTitle m-0">Verify
                    your Account</h1>
                <p>Please enter the passcode sent
                    on the registered email.</p>

                <el-form ref="verificationForm"
                    :model="verificationForm"
                    :rules="verificationRules"
                    class="mt-3"
                    label-position="top">

                    <el-form-item
                        label="Verification Code"
                        prop="verification_code">
                        <el-input
                            v-model="verificationForm.verification_code"
                            @keyup.enter.native="handleSubmit('verificationForm')"
                            placeholder="********" />
                    </el-form-item>


                    <el-button class="mt-3"
                        style="width: 100%"
                        type="success"
                        @click.prevent="handleSubmit('verificationForm')">Submit
                    </el-button>

                </el-form>

                <div class="text-center mt-3">
                    <el-button
                        @click.prevent="callToBackendVerificationApi"
                        :loading="loading"
                        type="text">Resend
                        Passcode
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Notification } from 'element-ui';

export default {
    name: "Signin",
    data() {
        return {
            formData: {
                email: null,
                password: null,
                remember_me: false,
            },
            rules: {
                email: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                    {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change']
                    }
                ],
                password: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                    {
                        min: 8,
                        message: 'Password must be at least 8 characters',
                        trigger: 'blur'
                    }
                ]
            },

            verificationForm: {
                verification_code: null,
            },

            verificationRules: {
                verification_code: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                    {
                        min: 6,
                        message: 'Verification code must be at least 6 characters',
                        trigger: 'blur'
                    }
                ]
            },

            showVerify: false,
            loading: false,
            showPassword: false
        };
    },
    methods: {
        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {

                if (valid) {
                    if (formName === 'form') {
                        this.callToBackendLoginApi();
                    } else {
                        this.callToBackendVerificationApi();
                    }
                } else {
                    return false;
                }
            });
        },

        callToBackendVerificationApi() {
            this.loading = true;
            this.$store.dispatch("auth/passcode", this.formData)
                .then(() => {
                    this.showVerify = true;
                    // Notification.success('Verification code sent to your email. Please check your email.');
                })
                .catch(() => {
                    Notification.error('Invalid email or password!');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        callToBackendLoginApi() {
            this.loading = true;
            this.$store.dispatch("auth/signin", {
                email: this.formData.email,
                password: this.formData.password,
                remember_me: this.formData.remember_me
            })
                .then(() => {
                    this.$router.push({ path: "/main-dashboard" });
                    Notification.success('Welcome back!');
                })
                .catch((err) => {
                    Notification.error(err.response.data.error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.formData.email = this.$route.query.email ? this.$route.query.email : null;
    },
};
</script>

<style scoped>
.auth-section {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-wrap: wrap;
}

.image-section {
    width: 50%;
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.image-section h1,
.form-section h1 {
    font-weight: 700;
    margin-top: 30px;
}

.form-section {
    width: 50%;
    background: #FFFFFF;
    padding: 50px 20px;
}

.form-section img {
    display: none;
}

@media (max-width: 991px) {
    .image-section {
        display: none;
    }

    .form-section h1 {
        font-size: 30px;
        text-align: center;
    }

    .form-content img {
        width: 200px;
        margin-bottom: 30px;
    }

    .form-section {
        width: 100%;
    }

    .form-section img {
        text-align: center;
        display: block;
    }
}

@media (max-width: 767px) {
    .form-section h1 {
        font-size: 24px;
        text-align: center;
    }

    .form-section {
        padding: 30px 20px;
    }

    .form-content img {
        width: 150px;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .pageTitle {
        font-size: 23px !important;
    }
}
</style>
